import React from 'react';

import HeaderNav from './header-nav';
import MobileNav from './mobile-nav';
import { HeaderContainer } from './styled';

const Header: React.FC<{ dark?: boolean }> = ({ dark = false }) => (
  <HeaderContainer inverted={dark}>
    <HeaderNav dark={dark} />
    <MobileNav dark={dark} />
  </HeaderContainer>
);

export default Header;
