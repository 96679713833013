import styled from 'styled-components';
import { breakpoints } from './variables';

interface BlockProps {
  relative?: boolean;
  margin?: string;
  width?: string;
  maxWidth?: string;
}

const Block = styled.div<BlockProps>`
  max-width: 100%;
  ${props => props.relative && 'position: relative;'}
  ${props => props.margin && `margin: ${props.margin};`}
    ${props => props.width && `width: ${props.width};`}

   @media (min-width: ${breakpoints.tablet}) {
    ${props => props.width && `width: ${props.width};`}
    max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  }
`;

export default Block;
