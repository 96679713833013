import React, { useEffect } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store';

import Header from './header';
import Footer from './footer';
import CookieBar from './cookie-bar';
import SEO from './seo';
import {
  Careers,
  ContactSales,
  ContactPartnerships,
} from '../components/forms';
import '../rc-select.css';
import '../styles/index.scss';
import { MainSiteContent } from './header/styled';

interface LayoutProps {
  children: React.ReactNode;
  withDarkHeader?: boolean;
  startOverlay?: string | null;
  title?: string;
  mainStyles?: React.CSSProperties;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  withDarkHeader,
  startOverlay,
  title,
  mainStyles,
}) => {
  const dispatch = useDispatch();
  const overlay = useSelector((state: State) => state.overlay);

  useEffect(() => {
    insertScripts();

    if (startOverlay)
      dispatch({
        type: 'SHOW_OVERLAY',
        payload: {
          target: startOverlay,
          id: `${startOverlay}-WS_ALL-${window.location.pathname.substr(1)}`,
        },
      });
  }, []);

  function insertScripts() {
    const captchaClientKey = '6Lfm7t4fAAAAALMQCiDhpuApDGkpnvxibOlLUNYT';

    const scripts = [
      { src: '/scripts/polyfills.min.js' },
      { src: 'https://cdn.statuspage.io/se-v2.js', defer: true },
      {
        src: '//js.hs-scripts.com/6061996.js',
        options: { async: true, defer: true, id: 'hs-script-loader' },
      },
      {
        src: `https://www.google.com/recaptcha/api.js?render=${captchaClientKey}`,
        options: { async: true, defer: true },
      },
    ];

    const inlineScripts = [
      /* eslint-disable */
      `(function(a,l,b,c,r,s){
        _nQc=c,r=a.createElement(l),s=a.getElementsByTagName(l)[0];
        r.async=1;
        r.defer=true;
        r.src=l.src=("https:"==a.location.protocol?"https://":"http://")+b;
        s.parentNode.insertBefore(r,s);})
        (document,"script","serve.albacross.com/track.js","89637491");`,
      /* eslint-enable */
    ];

    scripts.forEach(script => insertScriptWithSrc(script.src, script.options));
    inlineScripts.forEach(insertInlineScript);
  }

  function insertScriptWithSrc(src, options) {
    const script = document.createElement('script');
    if (options)
      Object.keys(options).forEach(key => {
        script[key] = options[key];
      });

    script.src = src;
    document.body.appendChild(script);
  }

  function insertInlineScript(content) {
    const script = document.createElement('script');
    script.innerHTML = content;
    document.body.appendChild(script);
  }

  return (
    <>
      <SEO
        title={
          title || 'One API for linking bank cards to applications, globally'
        }
      />
      <ReactCSSTransitionGroup
        transitionName="transitiongroup"
        transitionEnterTimeout={1000}
        transitionLeave={false}
      >
        {overlay === 'careers' && <Careers data-testid="careers-form" />}
        {overlay === 'contact_sales' && (
          <ContactSales data-testid="contact-form" />
        )}
        {overlay === 'contact_partnerships' && <ContactPartnerships />}
      </ReactCSSTransitionGroup>
      <div className={overlay ? 'hidden' : ''} style={{ overflow: 'clip' }}>
        <Header dark={withDarkHeader} />
        <MainSiteContent id="site-content" style={mainStyles}>
          {children}
        </MainSiteContent>
        <Footer />
        <CookieBar />
      </div>
    </>
  );
};

export default Layout;
