import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
  FooterLink as Link,
  NavList,
  NavSection,
  FooterNav as Nav,
  WrappedSectionContainer,
} from './styled';
import { StatusIndicator } from '../../components';

const FooterNav: React.FC = () => (
  <Nav>
    <FooterNavSections />
  </Nav>
);

const FooterNavSections: React.FC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            footerNav {
              wrapped
              items {
                title
                links {
                  href
                  label
                }
              }
              title
              links {
                href
                label
              }
            }
          }
        }
      }
    `,
  );

  const renderSingleSection = section => (
    <NavSection key={section.title}>
      <h4>{section.title}</h4>
      <NavList>
        {section.links.map((link, idx) => (
          <li key={`${link.href}_${idx}`}>
            <Link to={link.href}>
              {link.label}
              {link.href === 'https://status.fidel.uk' ? (
                <StatusIndicator appearance="dot" />
              ) : null}
            </Link>
          </li>
        ))}
      </NavList>
    </NavSection>
  );

  const renderWrappedSections = wrappedSection => (
    <WrappedSectionContainer>
      {wrappedSection.items.map(section => renderSingleSection(section))}
    </WrappedSectionContainer>
  );

  return site.siteMetadata.footerNav.map(section =>
    section.wrapped
      ? renderWrappedSections(section)
      : renderSingleSection(section),
  );
};

export default FooterNav;
