import React from 'react';

import Form from '../form';
import fields from '../form/common-fields';
import useJobsData from '../../hooks/use-jobs-data';
import Cubes from './assets/cubes.svg';

const Careers: React.FC = () => {
  const { offices, departments } = useJobsData();

  const departmentField = {
    label: 'Which department are you interested in?',
    id: 'department',
    type: 'searchable',
    options: departments.map(({ id, name }) => ({ code: id, name })),
    defaultValue: false,
    validation: {
      required: true,
    },
    validationMsg: 'Please select a department',
  };

  const officeField = {
    label: 'Which office are you interested in?',
    id: 'office',
    type: 'searchable',
    options: offices.map(({ id, name }) => ({ code: id, name })),
    defaultValue: false,
    validation: {
      required: true,
    },
    validationMsg: 'Please select one of our offices',
  };

  const props = {
    title: 'Let’s keep in touch!',
    formUrl: 'create-candidate',
    shouldCloseOnEsc: false,
    sideContent: (
      <>
        <p>
          We’ll keep you up to date with the latest news at Fidel API and let
          you know when we have new roles that may be a fit.
        </p>
        <Cubes
          style={{ width: '100%', maxWidth: '280px', marginTop: '-20px' }}
        />
      </>
    ),
    fields: [
      departmentField,
      officeField,
      fields.FIRST_NAME,
      fields.LAST_NAME,
      fields.EMAIL,
      fields.LINKEDIN,
      fields.SUBSCRIBE,
    ],
  };

  return <Form {...props} />;
};

export default Careers;
