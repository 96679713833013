import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '../section';
import StoryCard from './story-card';

const CustomerStoriesLinks: React.FC<{ current: string | null }> = ({
  current,
}) => {
  let customers: string[] = [
    'Perkbox',
    'Stampfeet',
    'Miconex',
    'Lux',
    'TopCashback',
    'Avios',
  ];

  const { assets } = useStaticQuery(assetsQuery);
  const cardBackgrounds = assets.edges.map(edge => edge.node);

  if (current) {
    const loop = [...customers, ...customers];
    const start = customers.indexOf(current) + 1;
    customers = loop.slice(start, start + 3).filter(cus => cus !== current);
  }

  return (
    <Section className="wide centered no-print">
      <h2>{current ? 'More customer stories' : 'Customer stories'}</h2>
      {!current && (
        <p>
          Explore how our partners are using Fidel API to build innovative
          applications
        </p>
      )}
      <div className="story-cards-container">
        {customers.map((customer: string) => {
          const img = cardBackgrounds.find(
            file => file.name === `small_thumb_${customer.toLowerCase()}`,
          );

          return (
            <StoryCard customer={customer} key={customer} background={img} />
          );
        })}
      </div>
    </Section>
  );
};

const assetsQuery = graphql`
  {
    assets: allFile(filter: { relativeDirectory: { eq: "stories" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

export default CustomerStoriesLinks;
