import React from 'react';
import styled from 'styled-components';

import { breakpoints } from './styled/variables';

const StyledGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 10px;

  input:not([type='checkbox']),
  button {
    width: 100%;
  }

  > :first-child {
    margin-bottom: 10px;
  }

  @media (min-width: ${breakpoints.largeMobile}) {
    max-width: 360px;
    flex-flow: row nowrap;

    > :first-child {
      margin-bottom: 0;
    }

    label {
      flex: 1 0 66%;

      input {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    button {
      min-width: 128px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

type InputGroupProps = React.HTMLAttributes<HTMLDivElement>;

const InputGroup: React.FC<InputGroupProps> = ({ children, ...rest }) => (
  <StyledGroup role="group" {...rest}>
    {children}
  </StyledGroup>
);

export default InputGroup;
