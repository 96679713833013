import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

interface LinkProps extends Record<string, any> {
  to: string;
  children?: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ to, children, ...rest }) => {
  let props = { ...rest };

  if (to.match(/^http(s)?:\/\//) || rest.download)
    props = { ...rest, target: '_blank', rel: 'noopener noreferrer' };

  return to.match(/^http(s)?:\/\//) || to.match(/^mailto:/) || rest.download ? (
    <a href={to} {...props}>
      {children}
    </a>
  ) : (
    <GatsbyLink to={to} {...props}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
