import React from 'react';
import Chevron from '../../components/assets/chevron.svg';
import { Route } from '../../templates/docs/routes';
import { NavItem } from './header-nav-link';
import NavDropdownMenu from './nav-dropdown-menu';
import { ChevronContainer } from './styled/index';

interface NavDropdownProps {
  id: string;
  items: Route[];
  open: boolean;
  unhovered: boolean;
  title: string;
  showSubmenu: (id: string) => void;
  hideSubmenu: () => void;
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  id,
  open,
  unhovered,
  title,
  items,
  showSubmenu,
  hideSubmenu,
}) => (
  <NavItem
    key={id}
    tabIndex={0}
    data-testid="nav-with-submenu"
    onFocus={() => showSubmenu(id)}
    onKeyDown={() => showSubmenu(id)}
    onMouseEnter={() => showSubmenu(id)}
    onMouseLeave={() => hideSubmenu()}
    aria-controls={`submenu-${id}`}
    unhovered={unhovered}
  >
    {title}
    <ChevronContainer open={open}>
      <Chevron />
    </ChevronContainer>
    <NavDropdownMenu
      id={`submenu-${id}`}
      items={items}
      visible={open}
      hide={hideSubmenu}
      firstLevel
    />
  </NavItem>
);

export default NavDropdown;
