import React from 'react';

interface EmptyCellProps {
  last?: boolean;
}

const EmptyCell: React.FC<EmptyCellProps> = ({ last }) => (
  <td style={{ borderTop: 'none', borderBottomWidth: last ? '1px' : '0' }} />
);

export default EmptyCell;
