import { useState, useEffect } from 'react';

export default function useStatusPage() {
  const [status, setStatus] = useState(null);

  useEffect(getStatusPageData, []);

  function getStatusPageData() {
    const { StatusPage } = window as any;

    if (StatusPage) {
      const sp = new StatusPage.page({ page: 'lcy7wb6vvwb6' });
      sp.status({
        success: data => {
          setStatus(data.status);
        },
      });
    } else {
      window.setTimeout(getStatusPageData, 100);
    }
  }

  return status;
}
