import React, { useEffect } from 'react';
import axios from 'axios';
import FullscreenLayout from '../fullscreen-layout/index';
import SEO from '../../layout/seo';
import { Block, Flex } from '../styled';

axios.defaults.headers.post['Content-Type'] = 'application/json';

interface FormProps {
  title: string;
  sideContent?: React.ReactNode;
  shouldCloseOnEsc?: boolean;
}

const Form: React.FC<FormProps> = ({
  title,
  sideContent,
  shouldCloseOnEsc = true,
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore next-line
      if (window.hbspt)
        // @ts-ignore next-line
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '6061996',
          formId: '77fe63ab-9d67-4423-a503-903bdbaf1e50',
          target: '#hubspotForm',
        });
    });
  }, []);
  return (
    <FullscreenLayout closeOnEsc={shouldCloseOnEsc}>
      <SEO title={title} />
      <Block width="956px" margin="0 auto">
        <Flex gap="100px">
          <Block width="280px">
            <h1>{title}</h1>
            {sideContent}
          </Block>
          <Block width="676px" margin="32px 0 0">
            <div id="hubspotForm" />
          </Block>
        </Flex>
      </Block>
    </FullscreenLayout>
  );
};

export default Form;
