import React, { useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '../../components/link';

import {
  CloseButton,
  Hamburger,
  MobileHeader,
  NavButton,
  NavContainer,
  NavList,
  ButtonsWrapper,
  ActionButton,
  ActionWrapper,
  TopIconsContainer,
} from './styled/mobile';

import MobileNavSection from './mobile-nav-section';
import { FidelWhiteLogo, FidelBlackLogo } from './styled';

const MobileNav: React.FC<{ dark: boolean }> = ({ dark }) => {
  const [isOpen, setOpen] = useState(false);

  const scrollingElement =
    typeof document !== 'undefined' ? document.scrollingElement : null;

  const open = () => {
    setOpen(true);

    scrollingElement?.setAttribute('style', 'overflow: hidden');
  };

  const close = () => {
    setOpen(false);

    scrollingElement?.setAttribute('style', '');
  };

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mobileNav {
              title
              links {
                href
                label
                title
                items {
                  href
                  label
                }
              }
            }
          }
        }
      }
    `,
  );

  const sections = site.siteMetadata.mobileNav;

  return (
    <MobileHeader>
      <Link to="/" className="logoLink">
        {dark ? <FidelWhiteLogo /> : <FidelBlackLogo />}
      </Link>
      <OpenButton onClick={open} />
      <ReactCSSTransitionGroup
        transitionName="transitiongroup"
        transitionEnterTimeout={400}
        transitionLeave={false}
      >
        {isOpen && (
          <NavContainer data-testid="mobile-nav">
            <TopIconsContainer>
              {dark ? <FidelWhiteLogo /> : <FidelBlackLogo />}
              <ActionWrapper>
                <CloseButton onClick={close} data-testid="mobile-close" />
              </ActionWrapper>
            </TopIconsContainer>
            <ButtonsWrapper>
              <ActionButton
                as={Link}
                variant="secondary"
                to="https://dashboard.fidel.uk/sign-in"
                target="_blank"
              >
                Log in
              </ActionButton>
              <ActionButton
                as={Link}
                variant="secondary"
                to="https://dashboard.fidel.uk/sign-up"
                target="_blank"
              >
                Sign up
              </ActionButton>
            </ButtonsWrapper>
            <nav role="navigation">
              <NavList>
                {sections.map((section, idx) => (
                  <MobileNavSection
                    key={idx}
                    {...section}
                    onClose={close}
                    isLast={idx >= sections.length - 1}
                  />
                ))}
              </NavList>
            </nav>
          </NavContainer>
        )}
      </ReactCSSTransitionGroup>
    </MobileHeader>
  );
};

const OpenButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <ActionWrapper>
    <NavButton
      onClick={onClick}
      data-testid="mobile-open"
      aria-label="Open menu"
      role="button"
    >
      <Hamburger />
    </NavButton>
  </ActionWrapper>
);

export default MobileNav;
