const prodUrl =
  'https://ah7v6xpif7.execute-api.eu-west-1.amazonaws.com/production';
const devUrl = 'https://vl0qukjsol.execute-api.eu-west-1.amazonaws.com/dev';
const localUrl = 'http://localhost:3000/dev';

const config = {
  baseUrl:
    process.env.GATSBY_APP_STAGE === 'dev'
      ? devUrl
      : process.env.GATSBY_APP_STAGE === 'local'
      ? localUrl
      : prodUrl,
  videoBaseUrl: 'https://resources.fidel.uk/website/videos',
};

export default config;
