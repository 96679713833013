import React from 'react';
import styled from 'styled-components';
import { breakpoints, colours } from './styled/variables';
import useStatusPage from '../hooks/use-status-page';

type Appearance = 'button' | 'dot';

interface StatusIndicatorProps {
  appearance: Appearance;
  children?: React.ReactNode;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  appearance,
  children,
}) => {
  const status: any = useStatusPage();

  return appearance === 'button' ? (
    <Btn status={status?.indicator}>{children}</Btn>
  ) : (
    <Dot data-testid="sp" status={status?.indicator} />
  );
};

type Status = string | undefined;

function getBgFromStatus(status: Status) {
  const { green, yellow, orange, red } = colours;

  if (status === 'none') return green;
  if (status === 'minor') return yellow;
  if (status === 'major') return orange;
  if (status === 'critical') return red;

  return green;
}

const Btn = styled.span<{ status: Status }>`
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: inline-block;
    height: 32px;
    padding: 0 18px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background: ${({ status }) => getBgFromStatus(status)};
    color: ${({ status }) => (status === 'minor' ? colours.primary : 'white')};

    font-size: 13px;
    font-weight: 600;
    line-height: 34px;
    text-transform: uppercase;

    margin: 8px 0 18px;
  }
`;

const Dot = styled.div<{ status: Status }>`
  position: absolute;
  left: calc(100% + 4px);
  top: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${({ status }) => getBgFromStatus(status)};
`;

export default StatusIndicator;
