import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { Offscreen } from '../styled';

interface StoryCardProps {
  customer: string;
  background: any;
}

const StoryCard: React.FC<StoryCardProps> = ({ customer, background }) => {
  const { srcLogo, srcSetLogo } = getLogo(customer);

  return (
    <div className="story-card">
      <Link to={`/stories/${customer.toLowerCase()}`}>
        <Offscreen>{customer}</Offscreen>
        <GatsbyImage
          image={background.childImageSharp.gatsbyImageData}
          alt={`See how ${customer} is using Fidel API`}
          className="story-card-img"
        />
        <div className="story-card-logo-wrapper">
          <img
            src={srcLogo}
            srcSet={srcSetLogo}
            className="story-card-logo"
            role="presentation"
          />
        </div>
      </Link>
    </div>
  );
};

function getLogo(customer: string) {
  const { src, srcSet } = getAsset(`${customer.toLowerCase()}_logo`);
  return { srcLogo: src, srcSetLogo: srcSet };
}

function getAsset(assetName: string) {
  const src = require(`../../modules/stories/images/small_thumb_${assetName}.png`);
  const src2x = require(`../../modules/stories/images/small_thumb_${assetName}@2x.png`);
  const srcSet = `${src.default}, ${src2x.default} 2x`;

  return { src, srcSet };
}

export default StoryCard;
