import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, StyledLink } from './styled';

interface OverlayTriggerProps extends Record<string, any> {
  id: string;
  children: React.ReactNode;
  target: string;
  appearance?: string;
}

const OverlayTrigger: React.FC<OverlayTriggerProps> = ({
  id,
  children,
  target,
  appearance = 'button',
  ...rest
}) => {
  const dispatch = useDispatch();

  const props = {
    onClick: () => dispatch({ type: 'SHOW_OVERLAY', payload: { target, id } }),
    'data-testid': 'overlay-trigger',
    ...rest,
  };

  return appearance === 'button' ? (
    <Button id={id} {...props}>
      {children}
    </Button>
  ) : (
    <StyledLink id={id} as="button" {...props}>
      {children}
    </StyledLink>
  );
};

export default OverlayTrigger;
