import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: any[];
  keywords?: string[];
  prefix?: string;
  image?: string;
  socialTitle?: string;
}

const defaultDescription =
  'Power your applications with real-time payment data. Build innovative card-linking applications by connecting Visa, Mastercard and Amex cards to loyalty and marketing services all in one place.';

const SEO: React.FC<SEOProps> = ({
  title,
  description = defaultDescription,
  lang = 'en',
  meta = [],
  keywords = [],
  prefix = 'Fidel API - ',
  image = '/fidel_social.png',
  socialTitle,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${prefix}%s`}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: `${prefix}${socialTitle || title}`,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: `${prefix}${socialTitle || title}`,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image:src',
          content: image,
        },
        {
          name: 'google-site-verification',
          content: 'xj715kweMKVOnO5ldubRl2IE063G2yhpCdLuX7Y5TAo',
        },
      ]
        .concat(
          keywords.length > 0
            ? { name: 'keywords', content: keywords.join(', ') }
            : [],
        )
        .concat(meta)}
    />
  );
};

export default SEO;
