import React, { useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';

import { Block, Offscreen } from './styled';
import Input, { InputProps } from './input';
import useOnClickOutside from './hooks/use-on-click-outside';
import { colours } from './styled/variables';

const keyCodes = {
  UP: 38,
  DOWN: 40,
  RETURN: 13,
};

interface SearchableInputProps {
  id: string;
  label: string;
  options: (string | number | any)[];
  onChange: (option: any) => unknown;
  searchLabel?: string;
  searchMatchFn?: (option: any, searchText: string) => boolean;
}
type SearchableInputWithRestProps = Merge<SearchableInputProps, InputProps>;

const SearchableInput = React.forwardRef<any, SearchableInputWithRestProps>(
  (
    {
      id,
      label,
      onChange = () => {},
      options: optionsFromOutside,
      searchLabel = 'Search…',
      searchMatchFn = (option, searchText) => {
        const regex = new RegExp(searchText, 'i');
        return option.name.match(regex) || option.code.toString().match(regex);
      },
      ...rest
    },
    ref,
  ) => {
    const options = useMemo(
      () =>
        optionsFromOutside.map(opt => {
          if (typeof opt === 'string') return { name: opt, code: opt };

          if (typeof opt === 'number')
            return { name: opt.toString(), code: opt };

          return opt;
        }),
      [optionsFromOutside],
    );

    const [cursor, setCursor] = useState(-1);
    const [panelOpen, setPanelOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [value, setValue] = useState('');

    const componentRef = useRef(null);
    const optionListRef = useRef<HTMLUListElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(componentRef, () => setPanelOpen(false));

    useEffect(() => {
      if (panelOpen && searchRef.current) searchRef.current.focus();
    }, [panelOpen]);

    function onSelect(selectedValue: string) {
      setValue(selectedValue);

      const option = options.find(opt => opt.name === selectedValue);

      if (option) {
        onChange({ type: 'search', id, ...option });
        setPanelOpen(false);
      }
    }

    const filteredOptions = options.filter(option =>
      searchMatchFn(option, search),
    );

    function handleA11ySelect(e: React.ChangeEvent<HTMLSelectElement>) {
      onSelect(e.target.value);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
      if ([keyCodes.UP, keyCodes.DOWN].includes(e.keyCode)) handleKeyNav(e);
      if (e.keyCode === keyCodes.RETURN) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        filteredOptions[cursor] && onSelect(filteredOptions[cursor].name);
        e.stopPropagation();
      }
    }

    function handleKeyNav(e: React.KeyboardEvent<HTMLDivElement>) {
      let idx = cursor;
      if (e.keyCode === keyCodes.UP && idx > 0) idx--;
      if (e.keyCode === keyCodes.DOWN && idx < filteredOptions.length - 1)
        idx++;

      if (optionListRef.current) {
        const nodeUnderCursor = optionListRef.current.childNodes[
          idx
        ] as Element;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        nodeUnderCursor && nodeUnderCursor.scrollIntoView({ block: 'nearest' });
      }

      setCursor(idx);
    }

    return (
      <Block relative ref={componentRef} onKeyDown={handleKeyDown}>
        <Input
          label={label}
          onFocus={() => {
            setPanelOpen(true);
          }}
          readOnly
          size="medium"
          type="text"
          value={value}
          ref={ref}
          variant="material"
          data-testid="searchable-input"
          {...rest}
        />
        <Offscreen>
          <select value={value} onChange={handleA11ySelect}>
            <option value="" disabled>
              {label}
            </option>
            {options.map(({ name, code }) => (
              <option value={code} key={code}>
                {name}
              </option>
            ))}
          </select>
        </Offscreen>
        {panelOpen && (
          <SearchPanel data-testid="search-panel">
            <Input
              label={searchLabel}
              onChange={e => setSearch(e.target.value)}
              placeholder={searchLabel}
              ref={searchRef}
              type="search"
              data-testid="search-input"
            />
            <OptionList ref={optionListRef}>
              {filteredOptions.map((option, idx) => (
                <Option
                  key={option.code}
                  highlighted={idx === cursor}
                  onClick={() => onSelect(option.name)}
                >
                  {option.name}
                </Option>
              ))}
            </OptionList>
          </SearchPanel>
        )}
      </Block>
    );
  },
);

const SearchPanel = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
  background: white;
  border: 1px solid ${colours.border};
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 4px;
`;

const OptionList = styled.ul`
  height: 200px;
  margin: 4px 0 0;
  padding: 0;
  overflow-y: auto;
  list-style: none;
`;

interface OptionProps {
  highlighted?: boolean;
}

const Option = styled.li.attrs(() => ({
  role: 'button',
}))<OptionProps>`
  background: ${({ highlighted }) =>
    highlighted ? colours.lightGrey : 'white'};
  padding: 6px 8px 5px;
  cursor: pointer;

  transition: background 0.2s ease;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colours.border};
  }

  &:focus,
  &:hover {
    background: ${colours.lightGrey};
  }
`;

export default SearchableInput;
