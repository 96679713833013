import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Link from '../../components/link';
import { breakpoints, colours } from '../../components/styled/variables';

interface NavLinkProps {
  title: string;
  href?: string;
  isContactLink?: boolean;
  unhovered: boolean;
}

const NavLink: React.FC<NavLinkProps> = ({
  href,
  title,
  isContactLink,
  unhovered,
  ...rest
}) => {
  const dispatch = useDispatch();

  function openContactForm() {
    dispatch({ type: 'SHOW_OVERLAY', payload: 'contact_sales' });
  }

  return (
    <NavItem key={href || title} unhovered={unhovered}>
      {isContactLink ? (
        <button onClick={openContactForm} type="button" {...rest}>
          {title}
        </button>
      ) : (
        <Link to={href || ''} {...rest}>
          {title}
        </Link>
      )}
    </NavItem>
  );
};

export const NavItem = styled.li<{ unhovered: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  margin-right: 24px;
  font-weight: 700;
  cursor: pointer;
  color: ${({ unhovered }) => unhovered && colours.midGrey2};

  @media (min-width: 1000px) {
    margin-right: 26px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-right: 40px;
  }

  svg:first-child {
    margin-bottom: 1px;
  }

  a {
    color: ${({ unhovered }) =>
      unhovered ? colours.midGrey2 : colours.black} !important;

    &:hover {
      color: ${colours.black} important;
    }
  }
`;

export default NavLink;
