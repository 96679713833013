import React, { useEffect, useRef } from 'react';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import javascript from 'highlight.js/lib/languages/javascript';
import html from 'highlight.js/lib/languages/xml';
import bash from 'highlight.js/lib/languages/bash';
import shell from 'highlight.js/lib/languages/shell';
import java from 'highlight.js/lib/languages/java';
import kotlin from 'highlight.js/lib/languages/kotlin';
import swift from 'highlight.js/lib/languages/swift';
import objectivec from 'highlight.js/lib/languages/objectivec';

import { CodeBody, Line, LinesContainer } from './styled';

hljs.registerLanguage('json', json);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('html', html);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('shell', shell);
hljs.registerLanguage('java', java);
hljs.registerLanguage('kotlin', kotlin);
hljs.registerLanguage('swift', swift);
hljs.registerLanguage('objectivec', objectivec);

export interface CodeProps {
  rightElement?: React.ReactElement;
  language?: string;
  title?: string;
  value: string;
  dark?: boolean;
}

const Code = React.forwardRef<any, CodeProps>(
  ({ value, language = 'json', dark }, ref) => {
    const codeRef = ref || useRef();

    const lines = value.split('\n');

    useEffect(() => {
      hljs.highlightBlock((codeRef as any).current);
    }, [value]);

    return (
      <CodeBody>
        <LinesContainer dark={dark}>
          {lines.map((_, idx) => (
            <Line key={idx}>{idx + 1}</Line>
          ))}
        </LinesContainer>
        <pre>
          <code ref={codeRef} className={`${language}${dark ? ' dark' : ''}`}>
            {value}
          </code>
        </pre>
      </CodeBody>
    );
  },
);

export default Code;
