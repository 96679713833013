export const breakpoints = {
  mobile: '380px',
  largeMobile: '440px',
  tabletPortrait: '600px',
  tablet: '850px',
  header: '900px',
  desktop: '1140px',
  above13inch: '1249px',
};

export const colours = {
  primary: '#000',
  secondary: '#a7b1bb',
  accent: '#0048ff',
  stringAccent: '#68fdef',
  titleAccent: '#1d68fa',
  lightAccent: '#dfe4e7',
  darkAccent: '#001976',
  blueBorder: '#609bff',
  border: '#edeeef',
  border2: '#eaeaea',
  codeBg: '#fbfbfb',
  codeInlineBg: '#f1f1f1',
  codeLightGrey: '#c6c6c6',
  greyBg: '#efefef',
  darkGrey: '#4c5b63',
  darkGrey2: '#202020',
  darkGrey3: '#34373a',
  darkGrey4: '#303030',
  midGrey: '#8a96a3',
  midGrey2: '#a1a0a2',
  midGrey3: '#5e6167',
  midGrey4: '#9e9e9e',
  midGrey5: '#808080',
  lightGrey: '#f0f2f6',
  lightGrey2: '#f7f8f9',
  lightGrey3: '#efefef',
  lightGrey4: '#f0f0f0',
  lightGrey5: '#ececec',
  lightGrey6: '#dbdbdb',
  lightGrey7: '#e0e0e0',
  lightGrey8: '#647174',
  darkInputBg: '#64757e',
  green: '#39d267',
  yellow: '#fd2',
  orange: '#ff5f1b',
  orange2: '#ffb24b',
  red: '#e00',
  pink: '#ec44ac',
  brown: '#8b572a',
  black: '#000',
  white: '#fff',

  transparent: 'transparent',
  placeholder: '#a0a9b1',
  newLightGray: '#f7f8f9',
  codeBlockBg: '#121417',
  shadow: '#00000014',
  blue: '#105efb',
};

export const fonts = {
  titles: `
    font-family: 'Aeonik', -apple-system, BlinkMacSystemFont, 'Segoie UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-feature-settings: 'ss02' on;
  `,
  body: `
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoie UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  `,
};

export const defaults = {
  borderRadius: '32px',
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
  translucentBorder: `
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
    background-clip: padding-box !important;
  `,
};

export type ScreenSize = 'smallScreen' | 'largeScreen';
export type Size = 'small' | 'normal' | 'medium' | 'large';

export const input = {
  defaults: `
    font-size: 16px;
    ${fonts.body};
    color: ${colours.primary};
    height: 38px;
    border: none;
    border-radius: ${defaults.borderRadius};
    padding: 0 12px;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    &:-moz-focus-outer {
      border: none;
    }

    &::placeholder {
      color: ${colours.placeholder};
    }
  `,
  fontSize: '16px',
  border: `1px solid ${colours.placeholder}`,
  size: {
    smallScreen: {
      small: '32px',
      normal: '38px',
      medium: '40px',
      large: '58px',
    },
    largeScreen: {
      small: '32px',
      normal: '38px',
      medium: '48px',
      large: '70px',
    },
  },
};
