import React from 'react';

interface SectionProps extends Record<string, any> {
  children: React.ReactNode;
  className?: string;
}

const Section: React.FC<SectionProps> = ({
  children,
  className = '',
  ...rest
}) => (
  <section className={`section ${className}`} {...rest}>
    {children}
  </section>
);

export default Section;
