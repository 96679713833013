import styled from 'styled-components';

const Offscreen = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
`;

export default Offscreen;
