import styled from 'styled-components';
import { breakpoints, colours } from '../../styled/variables';
import { CodeBody } from '../code/styled';

export const CodeHeader = styled.div`
  padding: 26px 0 6px;
  margin: 0 24px;
  border-bottom: 1px solid ${colours.border};

  h4 {
    margin: 0;
    font-weight: 500;
    color: ${colours.darkGrey};
  }
`;

export const LineNumbers = styled.div<{
  isShellCode: boolean;
  hasTitle: boolean;
}>`
  position: absolute;
  background: ${colours.codeBg};
  top: ${props => (props.hasTitle ? '54px' : '0px')};
  left: 0;
  width: 3.7em; // works for line-numbers below 1000 lines
  letter-spacing: -1px;
  pointer-events: none;
  user-select: none;
  padding-top: 1.1em;

  span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber;
    line-height: 1.7;

    &:before {
      display: block;
      content: ${props => (props.isShellCode ? '"$"' : 'counter(linenumber)')};
      color: ${colours.codeLightGrey};
      padding-right: 0.8em;
      text-align: right;
    }

    @media (min-width: ${breakpoints.tablet}) {
      line-height: 1.6;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    top: ${props => (props.hasTitle ? '55px' : '0px')};
  }
`;

const responsiveFontSize = `
  font-size: 14px;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 15px;
  }
`;

export const Block = styled.div`
  width: 100vw;
  position: relative;
  left: -20px;
  margin: 0.5em 0 1.8em;
  border: 2px solid ${colours.border};
  background: ${colours.codeBg};

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    left: 0;
    border-radius: 6px;
  }

  pre {
    margin: 20px 0 14px;
    padding: 0 40px;
    counter-reset: linenumber;

    @media (min-width: ${breakpoints.tablet}) {
      padding: 0 44px 0 46px;
    }
  }

  ${CodeBody} {
    padding-bottom: 0;

    pre {
      ${responsiveFontSize}
    }
  }

  ${LineNumbers} {
    ${responsiveFontSize}
  }
`;
