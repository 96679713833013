import React, { useState } from 'react';

import config from '../utils/config';
import { Offscreen } from './styled';
import PlayIcon from '../static/icons/play.svg';

interface VideoProps {
  id: string;
  src: string;
  description: string;
  playBtnAlt: string;
  btnOnly: boolean;
}

const Video: React.FC<VideoProps> = ({
  id,
  src,
  description,
  playBtnAlt,
  btnOnly,
}) => {
  const [fullscreen, setFullscreen] = useState(false);

  function toggleOverlay(e, videoId) {
    const shouldBeFullscreen =
      !fullscreen || !e.target.src ? !fullscreen : fullscreen;
    const video = document.getElementById(videoId) as any;
    if (shouldBeFullscreen) video?.play();
    else video?.pause();
    setFullscreen(shouldBeFullscreen);
  }

  const wrapperClass = `video-wrapper${
    fullscreen ? ' fullscreen' : btnOnly ? ' btn-only' : ''
  }`;

  return (
    <div
      className={wrapperClass}
      onClick={e => {
        toggleOverlay(e, id);
      }}
      onKeyDown={e => {
        toggleOverlay(e, id);
      }}
      role="presentation"
      data-testid="video-wrapper"
    >
      {!fullscreen && (
        <div>
          <Offscreen>{playBtnAlt}</Offscreen>
          <PlayIcon className="play-btn" />
        </div>
      )}
      <video
        src={`${config.videoBaseUrl}/${src}`}
        controls={fullscreen}
        hidden={!fullscreen}
        id={id}
        loop
        data-testid="video"
      />
      <Offscreen>{description}</Offscreen>
    </div>
  );
};

export default Video;
