import React from 'react';

import Code from '../code';
import { Block, CodeHeader, LineNumbers } from './styled';

interface CodeBlockProps {
  children: string;
  className?: string;
}

function extractDetail(name: string, code: string) {
  let newCode = code;
  let value: string | null = null;

  const start = code.indexOf(`${name}:`) ?? 0;

  if (start >= 0) {
    const end = code.indexOf('\n', start);
    value = code.substring(start + name.length + 1, end);
    newCode = code.substring(end + 1);
  }

  return { value, code: newCode };
}

const CodeBlock: React.FC<CodeBlockProps> = ({
  children,
  className,
  ...rest
}) => {
  if (!children.includes('\n')) return <code>{children}</code>;

  const language = className?.split('language-')[1] || 'json';

  const { value: summary, code: codeWithoutSummary } = extractDetail(
    'summary',
    children,
  );
  const { value: fileName, code } = extractDetail(
    'fileName',
    codeWithoutSummary,
  );

  const lineNumbers = (() => {
    const linesLength = children.split('\n').length;
    const filenameOffset = fileName ? 1 : 0;
    const summaryOffset = summary ? 1 : 0;

    return new Array(linesLength - filenameOffset - summaryOffset)
      .fill('')
      .map((_, idx) => <span key={idx} />);
  })();

  const Header: React.FC<{ title: string }> = ({ title }) => (
    <CodeHeader>
      <h4>{title}</h4>
    </CodeHeader>
  );

  const block = (
    <Block>
      {fileName && <Header title={fileName} />}
      <Code value={code} language={language} {...rest} />
      <LineNumbers
        hasTitle={!!fileName}
        isShellCode={language.includes('sh')}
        aria-hidden
      >
        {lineNumbers}
      </LineNumbers>
    </Block>
  );

  if (!summary) return block;

  return (
    <details>
      <summary>{summary}</summary>
      <div>{block}</div>
    </details>
  );
};

export default CodeBlock;
