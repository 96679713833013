import styled from 'styled-components';
import { RoundedButton } from '../../../components/styled/button';
import { breakpoints, colours } from '../../../components/styled/variables';

export const MobileHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;

  @media (min-width: ${breakpoints.desktop}) {
    display: none;
  }

  @media (max-width: ${breakpoints.largeMobile}) {
    margin-bottom: 20px;

    .logoLink {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
`;

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: 0 20px 100px;
  background: ${colours.white};
  color: ${colours.primary};
`;

export const TopIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  width: 100%;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-content: center;
  margin-left: auto;
  height: 100%;
`;

export const NavButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  -webkit-appearance: none;

  &::before,
  &::after {
    display: block;
    content: ' ';
    position: absolute;
    top: 28px;
  }
`;

export const NavList = styled.ul`
  padding-left: 0;
  list-style: none;

  li {
    margin: 10px 0;
  }

  .mobile-nav-section-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: ${colours.midGrey4};
    text-transform: uppercase;
  }

  a,
  .open-contact-form-button {
    color: ${colours.primary};
    font-size: 20px;
    font-weight: 500;
    border: none;

    &:hover,
    &:focus {
      opacity: 0.85;
    }
  }

  .open-contact-form-button {
    border: none;
    background: none;
    padding: 0;
    font-family: 'Inter';
  }

  hr {
    width: 100%;
    height: 1px;
    text-align: left;
    margin: 30px auto 15px 0;
    border: none;
    background: ${colours.lightGrey5};
  }
`;

export const NavSection = styled.ul`
  padding-left: 0;
  list-style: none;

  & :not(:first-child) {
    div span {
      padding-top: 16px;
      display: block;
    }
  }
`;

export const NavItem = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 500;
`;

export const NavSubItem = styled.div`
  padding: 14px 0 0 14px;
  a {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ActionButton = styled(RoundedButton)`
  width: 100%;
  justify-content: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  border-bottom: 1px solid ${colours.lightGrey5};
  padding-bottom: 20px;

  a {
    width: 100%;
    margin: 0 auto;
    text-align: center !important;

    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const Hamburger = styled.div`
  margin-left: auto;
  position: relative;
  width: 22px;
  height: 2px;
  background: ${colours.primary};
  pointer-events: none;

  &::after {
    display: block;
    content: ' ';
    top: 8px;
    width: 22px;
    height: 2px;
    position: relative;
    background: ${colours.primary};
  }
`;

export const CloseButton = styled(NavButton)`
  &::before,
  &::after {
    width: 22px;
    height: 2px;
    background: ${colours.black};
    right: 24px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(135deg);
  }
`;
