import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import Link from './link';
import { Button, FinePrint } from './styled';
import { Input, InputGroup } from '.';

import config from '../utils/config';
import { getHubspotCookie } from '../utils/hubspot';

axios.defaults.headers.post['Content-Type'] = 'application/json';

interface SubscribeFormProps {
  id: string;
  btnText?: string;
  className?: string;
  size?: 'small' | 'normal' | 'medium' | 'large' | undefined;
}

const SubscribeForm: React.FC<SubscribeFormProps> = ({
  id,
  btnText = 'Subscribe',
  className = 'subscribe-form',
  size = 'medium',
}) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [timer, setTimer] = useState<number | undefined>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    verifyCaptcha();
    return () => clearTimeout(timer);
  }, []);

  function verifyCaptcha() {
    if ((window as any)?.grecaptcha) {
      const { grecaptcha } = window as any;
      grecaptcha?.ready(() => {
        clearRecaptchaBadge();
        grecaptcha
          .execute('6Lfm7t4fAAAAALMQCiDhpuApDGkpnvxibOlLUNYT', {
            action: 'subscribe_captcha',
          })
          .then(token => {
            axios
              .post(`${config.baseUrl}/verify-captcha`, { token })
              .then(res => {
                setCaptchaVerified(res.data && res.data.success);
              })
              .catch(error => {
                console.error(error);
              });
          });
      });
    } else {
      setTimer(window.setTimeout(() => verifyCaptcha(), 50));
    }
  }

  function clearRecaptchaBadge() {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge) (badge as HTMLElement).style.display = 'none';
  }

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      const data = {
        email,
        captchaVerified,
        hutk: getHubspotCookie(),
        ctaSource: id,
        sourceUrl: window.location.href,
      };

      axios.post(`${config.baseUrl}/subscribe`, data).then(
        () => {
          clearTimeout(timer);
          clearInput();
          setShowError(false);
          setShowSuccess(true);
          setTimer(
            window.setTimeout(() => {
              setShowSuccess(false);
            }, 3500),
          );
        },
        () => {
          clearTimeout(timer);
          setShowSuccess(false);
          setShowError(true);
          setTimer(
            window.setTimeout(() => {
              setShowError(false);
            }, 6000),
          );
        },
      );
    },
    [email, captchaVerified],
  );

  function clearInput() {
    setEmail(null);
    const input = document.querySelector('.subscribe-form .input') as any;
    input?.blur();
  }

  const smallInputStyle = {
    padding: '0 3px',
  };

  const smallBtnStyle = {
    minWidth: 0,
    width: btnText.length < 3 ? '32px' : 'auto',
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      <InputGroup>
        <Input
          size={size}
          type="email"
          name="email"
          label="Your e-mail"
          placeholder="you@company.com"
          onChange={e => {
            setEmail(e.target.value);
          }}
          data-testid="subscribe-input"
          autoCorrect="off"
          spellCheck="false"
          autoCapitalize="off"
          style={size === 'small' ? smallInputStyle : undefined}
        />
        <Button
          type="submit"
          data-testid="subscribe-btn"
          size={size}
          style={size === 'small' ? smallBtnStyle : undefined}
        >
          {btnText}
        </Button>
      </InputGroup>
      {captchaVerified && (
        <FinePrint>
          Protected by reCAPTCHA.{' '}
          <Link to="https://www.google.com/intl/en/policies/privacy/">
            Privacy
          </Link>
          {' & '}
          <Link to="https://www.google.com/intl/en/policies/terms/">Terms</Link>
        </FinePrint>
      )}
      <input type="hidden" name="subscribe_captcha" value="subscribe_captcha" />
      {showSuccess ? (
        <p className="subscribe-form-success">
          Thanks for subscribing —{' '}
          <span className="nowrap">we’ll be in touch!</span>
        </p>
      ) : null}
      {showError ? (
        <p className="subscribe-form-error">Something went wrong.</p>
      ) : null}
    </form>
  );
};

export default SubscribeForm;
