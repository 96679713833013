import React from 'react';
import styled from 'styled-components';

import { Link, Section } from '../../../components';
import { colours } from '../../../components/styled/variables';
import { Layout, SEO } from '../../../layout';
import DownloadIcon from '../assets/download_icon.svg';

export const SubHeader = styled.span`
  color: ${colours.accent};
  text-decoration: none;
  position: relative;
  font-weight: 500;
`;

interface LegalDocProps {
  title: string;
  children: React.ReactNode[];
  downloadLink?: string;
  updated?: string;
}

const LegalDoc: React.FC<LegalDocProps> = ({
  title,
  children,
  downloadLink,
  updated,
}) => (
  <Layout>
    <SEO title={title} />
    <Section className="legal-section inner">
      <p>
        <Link to="/legal">← Back to legal documents</Link>
      </p>
      <h1 className="text-left">{title}</h1>
      {downloadLink && (
        <p>
          <Link
            download
            to={`/documents/${downloadLink}`}
            className="download-link"
          >
            Download
            <DownloadIcon />
          </Link>
        </p>
      )}
      {updated && (
        <time className="secondary-text">Last updated: {updated}</time>
      )}
      {children}
    </Section>
  </Layout>
);

export default LegalDoc;
