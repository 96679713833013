import React from 'react';
import { Provider } from 'react-redux';

import createStore from './src/store';

export function wrapRootElement({ element }) {
  const store = createStore();
  return <Provider store={store}>{element}</Provider>;
}

export function onRouteUpdate({ location }) {
  const { pathname } = location;

  const entryPath = pathname;
  const subpathMatch = entryPath.match(/^\/[\w-]+\/(.+)/);
  const subpath = subpathMatch ? subpathMatch[1] : '';

  const redirectRules = [
    { pattern: /^\/customers/, toPath: `/stories/${subpath}` },
    { pattern: /^\/product-demo/, toPath: '/contact/' },
    { pattern: /^\/demo/, toPath: '/contact/' },
    { pattern: /^\/legal\/terms$/, toPath: '/legal/' },
    { pattern: /^\/use-cases\/?$/, toPath: '/use-cases/rewards/' },
    {
      pattern: /^\/use-cases\/attribution\/?$/,
      toPath: '/use-cases/attribution-and-insights/',
    },
    {
      pattern: /^\/docs\/cards\/testing-card-numbers/,
      toPath: '/docs/cards/#testing-card-numbers',
    },
    { pattern: /^\/docs\/web-sdk\/?$/, toPath: '/docs/web-sdk/v3' },
    { pattern: /^\/products\/?$/, toPath: '/' },
  ];

  const shouldRedirect = redirectRules.find(({ pattern }) =>
    pattern.test(entryPath),
  );

  if (shouldRedirect) redirect(shouldRedirect.toPath);
}

export function onInitialClientRender() {
  const { hash } = window.location;

  if (hash) scrollToElement(hash.substr(1));
}

function redirect(toPath) {
  const target = /^https?:/.test(toPath)
    ? toPath
    : `${window.location.protocol}//${window.location.host}${toPath}`;

  window.location.replace(target);
}

function scrollToElement(id, tries = 0) {
  tries++;

  if (tries < 3) {
    const element = document.getElementById(id);

    if (element) element.scrollIntoView();
    else window.setTimeout(() => scrollToElement(id, tries), 0);
  }
}
