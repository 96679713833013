import React, { useState } from 'react';
import Link from '../../components/link';
import Arrow from '../../static/icons/arrow.svg';
import {
  IconWrapper,
  PanelSensitivityArea,
  NavDropdownMenuItem,
  NavDropdownMenuList,
  NavDropdownMenuPanel,
  SubItemTitle,
  SubItemDescription,
  NavDropdownMenuCategoryLabel,
  submenuIcons,
} from './styled';
import Chevron from '../../components/assets/chevron.svg';

interface NavDropdownMenuProps {
  id: string;
  items: any[];
  visible: boolean;
  hide: () => void;
  firstLevel: boolean;
}

const NavDropdownMenu: React.FC<NavDropdownMenuProps> = ({
  items,
  visible,
  hide,
  id,
  firstLevel,
}) => (
  <NavDropdownMenuPanel id={id} visible={visible} firstLevel={firstLevel}>
    <NavDropdownMenuList onMouseLeave={hide}>
      <PanelSensitivityArea />
      {items.map(({ category, items: NavDropdownMenuItems }) => (
        <div key={category}>
          {category && (
            <NavDropdownMenuCategoryLabel>
              {category}
            </NavDropdownMenuCategoryLabel>
          )}
          {NavDropdownMenuItems.map((item, idx) => {
            const className =
              idx === items.length - 1 ? 'last-submenu-item' : '';

            if (item.items)
              return (
                <NestedNavDropdownMenuItem
                  title={item.title}
                  key={idx}
                  items={item.items}
                  className={className}
                  id={item.id}
                />
              );

            if (category)
              return (
                <CategoryNavDropdownMenuItem
                  key={idx}
                  item={item}
                  className={className}
                  id={idx}
                />
              );

            return (
              <SimpleNavDropdownMenuItem
                key={idx}
                item={item}
                className={className}
                id={idx}
              />
            );
          })}
        </div>
      ))}
    </NavDropdownMenuList>
  </NavDropdownMenuPanel>
);

interface NavDropdownMenuItemProps {
  id: string;
  className: string;
  item: Record<string, string>;
}

const SimpleNavDropdownMenuItem: React.FC<NavDropdownMenuItemProps> = ({
  item,
  className,
  id,
}: NavDropdownMenuItemProps) => (
  <NavDropdownMenuItem key={item.label} padding="10px" as={Link} to={item.href}>
    <SubItemTitle className={className} data-testid={`submenu-link-${id}`}>
      {item.label}
    </SubItemTitle>
    <IconWrapper>
      <Arrow
        className="arrowRight"
        style={{
          height: '20px',
          width: '20px',
          position: 'relative',
          top: '2px',
          left: '13px',
        }}
      />
    </IconWrapper>
  </NavDropdownMenuItem>
);

interface NestedNavDropdownMenuItemProps {
  id: string;
  title: string;
  items: Array<Record<string, string>>;
  className: string;
}

const NestedNavDropdownMenuItem: React.FC<NestedNavDropdownMenuItemProps> = ({
  title,
  items,
  id,
  className,
}: NestedNavDropdownMenuItemProps) => {
  const [isHighlightedNestedMenu, setIsHighlightedNestedMenu] = useState(false);

  return (
    <NavDropdownMenuItem
      key={id}
      tabIndex={0}
      padding="10px"
      onClick={() => setIsHighlightedNestedMenu(true)}
      onFocus={() => setIsHighlightedNestedMenu(true)}
      onKeyDown={() => setIsHighlightedNestedMenu(true)}
      onMouseEnter={() => setIsHighlightedNestedMenu(true)}
      onMouseLeave={() => setIsHighlightedNestedMenu(false)}
    >
      <SubItemTitle className={className} data-testid={`submenu-link-${id}`}>
        {title}
      </SubItemTitle>
      <IconWrapper>
        <Chevron
          className="chevronRight"
          style={{
            position: 'relative',
            left: '13px',
            transform: 'rotate(-90deg)',
          }}
        />
        <NavDropdownMenu
          id={`submenu-${id}`}
          items={items}
          visible={isHighlightedNestedMenu}
          hide={() => setIsHighlightedNestedMenu(false)}
          firstLevel={false}
        />
      </IconWrapper>
    </NavDropdownMenuItem>
  );
};

const CategoryNavDropdownMenuItem: React.FC<NavDropdownMenuItemProps> = ({
  item,
  className,
  id,
}: NavDropdownMenuItemProps) => (
  <NavDropdownMenuItem
    key={item.label}
    padding="14px 0"
    as={Link}
    to={item.href}
  >
    {item.icon && <IconWrapper>{submenuIcons[item.icon]}</IconWrapper>}
    <div>
      <SubItemTitle className={className} data-testid={`submenu-link-${id}`}>
        {item.label}
      </SubItemTitle>
      {item.description && (
        <SubItemDescription>{item.description}</SubItemDescription>
      )}
    </div>
    <IconWrapper className="arrowRight">{submenuIcons.arrowRight}</IconWrapper>
  </NavDropdownMenuItem>
);

export default NavDropdownMenu;
