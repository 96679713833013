import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Block, Offscreen } from '../styled';
import { CloseBtn, Overlay } from './styled';

interface FullscreenLayoutProps {
  children: React.ReactNode;
  closeOnEsc?: boolean;
  invertedCloseBtn?: boolean;
}

const FullscreenLayout: React.FC<FullscreenLayoutProps> = ({
  children,
  closeOnEsc = true,
  invertedCloseBtn,
}) => {
  const dispatch = useDispatch();

  function onClose() {
    dispatch({ type: 'HIDE_OVERLAY' });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (closeOnEsc)
      window.addEventListener('keydown', e => {
        if (e.keyCode === 27) onClose();
      });
  }, []);

  return (
    <Overlay id="site-content">
      <Block>
        <CloseBtn
          onClick={onClose}
          inverted={invertedCloseBtn}
          data-testid="close-btn"
        >
          <Offscreen>Close</Offscreen>
        </CloseBtn>
        {children}
      </Block>
    </Overlay>
  );
};

export default FullscreenLayout;
