import styled from 'styled-components';
import { breakpoints, colours } from '../styled/variables';

export const Overlay = styled.main`
  position: relative;
  margin: 20px;
  background: ${colours.greyBg};
  padding: 20px 20px 40px;
  min-height: calc(100vh - 40px);

  @media (min-width: ${breakpoints.tabletPortrait}) {
    overflow: hidden;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 60px;
  }

  @media (min-height: 850px) {
    padding-top: 85px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 30px;
    min-height: calc(100vh - 60px);
  }
`;

export const CloseBtn = styled.button.attrs(() => ({
  type: 'button',
}))<{ inverted?: boolean }>`
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;

  @media (min-width: ${breakpoints.tablet}) {
    top: 5px;
    right: 5px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    top: 20px;
    right: 20px;
  }

  &::before,
  &::after {
    position: absolute;
    top: calc(50% - 13px);
    right: 24px;
    content: ' ';
    height: 26px;
    width: 2px;
    background: ${props =>
      props.inverted ? 'rgba(255, 255, 255, .8)' : '#888'};
    transition: background 0.3s ease;
    border-radius: 1px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    &::before,
    &::after {
      background: ${props => (props.inverted ? 'white' : '#111')};
    }
  }
`;
