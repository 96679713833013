import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import NavDropdown from './header-nav-dropdown';
import NavLink from './header-nav-link';
import Link from '../../components/link';
import {
  FidelBlackLogo,
  FidelWhiteLogo,
  SignUpButton,
  Nav,
  NavList,
  LoginButton,
} from './styled';
import { Flex, Offscreen } from '../../components/styled';

const HeaderNav: React.FC<{ dark: boolean }> = ({ dark }) => {
  const [highlightedItem, setHighlightedItem] = useState(null);
  const { site } = useStaticQuery(headerQuery);

  const isItemUnhovered = (id: string) =>
    !!highlightedItem && highlightedItem !== id;

  return (
    <Nav role="navigation">
      <Flex alignItems="center">
        <Link to="/">
          <Flex alignItems="center">
            {dark ? <FidelWhiteLogo /> : <FidelBlackLogo />}
            <Offscreen>
              <p>Fidel API - Home</p>
            </Offscreen>
          </Flex>
        </Link>
        <NavList>
          {site.siteMetadata.headerNav.map(item =>
            item.items ? (
              <NavDropdown
                key={item.id}
                {...item}
                open={highlightedItem === item.id}
                unhovered={isItemUnhovered(item.id)}
                showSubmenu={setHighlightedItem}
                hideSubmenu={() => setHighlightedItem(null)}
              />
            ) : (
              <NavLink
                key={item.href}
                isContactLink={item.isContactLink}
                unhovered={isItemUnhovered(item.title)}
                onMouseEnter={() => setHighlightedItem(item.title)}
                onMouseLeave={() => setHighlightedItem(null)}
                {...item}
              />
            ),
          )}
        </NavList>
      </Flex>
      <Flex alignItems="center">
        <LoginButton
          as={Link}
          to="https://dashboard.fidel.uk/sign-in"
          variant="white"
        >
          Log in
        </LoginButton>
        <SignUpButton
          id="dashboardSignUp-header"
          as={Link}
          to="https://dashboard.fidel.uk/sign-up"
          variant="secondary"
        >
          Sign up
        </SignUpButton>
      </Flex>
    </Nav>
  );
};

const headerQuery = graphql`
  query {
    site {
      siteMetadata {
        headerNav {
          title
          id
          href
          items {
            category
            items {
              icon
              href
              label
              description
              title
              id
              items {
                items {
                  href
                  label
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HeaderNav;
