import styled from 'styled-components';
import { colours } from '../styled/variables';

export const SwitchLocaleBtn = styled.button<{ active?: boolean }>`
  appearance: none;
  background: none;
  border: none;
  padding: 0;

  display: flex;
  align-items: center;

  > :first-child {
    height: 20px;
    width: 28px;
  }

  > :last-child {
    margin-left: 4px;
    transition: transform 0.3s ease;
    height: 12px;
    width: 12px;
  }

  ${props =>
    props.active &&
    `
  >:last-child {
    transform: rotate(-180deg);
  }
`}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Panel = styled.ul`
  z-index: 10000;
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 160px;

  background: ${colours.white};
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  padding: 5px;
  list-style: none;
`;

export const PanelItem = styled.li`
  padding-left: 15px;
  background: ${colours.white};
  transition: background 0.3s ease;
  cursor: pointer;
  color: ${colours.primary};
  border-radius: 8px;

  font-size: 14px;
  line-height: 2.2;
  letter-spacing: 0.3px;

  &:hover,
  &:focus {
    background: #eceff2;
  }
`;
