import React, { useState } from 'react';

import { Button } from '../components/styled';
import Link from '../components/link';

const CookieBar: React.FC = () => {
  const [hasAcceptedCookies] = useState(
    typeof localStorage !== 'undefined' &&
      localStorage.getItem('fidel-cookies-accepted'),
  );

  function handleClickDismiss() {
    const cookieBar = document.getElementById('cookie-bar');
    cookieBar?.classList.add('cookie-bar-dismissed');
    setTimeout(() => {
      localStorage.setItem('fidel-cookies-accepted', 'true');
    }, 800);
  }

  return hasAcceptedCookies ? null : (
    <div className="cookie-bar" id="cookie-bar">
      <p className="cookie-notice">
        By using this site, you agree to our{' '}
        <Link to="/legal/marketing-and-cookies">Cookie Policy</Link>
      </p>
      <Button
        variant="dark"
        size="small"
        style={{ marginLeft: '10px' }}
        onClick={handleClickDismiss}
        type="button"
        data-testid="accept-cookies-btn"
      >
        Dismiss
      </Button>
    </div>
  );
};

export default CookieBar;
