import styled from 'styled-components';
import { breakpoints } from './variables';

export const Blockquote = styled.blockquote`
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.39;
  letter-spacing: 0.5px;
  text-align: center;

  cite {
    display: block;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    margin-top: 34px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 22px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 24px;
  }

  @media (min-width: ${breakpoints.above13inch}) {
    font-size: 32px;
  }
`;
