import React from 'react';
import ThumbUpIcon from '../static/icons/thumb-up-icon.svg';

interface BenefitsProps {
  items: Benefit[];
  title?: string;
  compact?: boolean;
  icon?: React.ReactNode;
}

export interface Benefit {
  description: React.ReactNode;
  name?: string;
}

const Benefits: React.FC<BenefitsProps> = ({
  title,
  items,
  icon = <ThumbUpIcon />,
  compact,
}) => (
  <>
    {title && <h2 className="narrow">{title}</h2>}
    {compact ? (
      <ul className="benefits-list compact">
        {items.map(({ description }, idx) => (
          <li key={idx} className="benefits-list-item">
            {icon}
            <span>{description}</span>
          </li>
        ))}
      </ul>
    ) : (
      <dl className="benefits-list">
        {items.map(({ name, description }) => (
          <div className="benefits-list-item" key={name}>
            {icon}
            <dt>{name}</dt>
            <dd>{description}</dd>
          </div>
        ))}
      </dl>
    )}
  </>
);

export default Benefits;
