import React from 'react';
import { useDispatch } from 'react-redux';
import Link from '../../components/link';
import { NavItem, NavSection, NavSubItem } from './styled/mobile';

interface MobileNavSectionProps {
  links: any[];
  isLast: boolean;
  onClose: () => void;
  title?: string;
}

const MobileNavSection: React.FC<MobileNavSectionProps> = ({
  title,
  links,
  onClose,
  isLast,
}) => {
  const dispatch = useDispatch();

  function openContactForm() {
    dispatch({ type: 'SHOW_OVERLAY', payload: 'contact_sales' });
  }

  return (
    <li>
      {title && <h3 className="mobile-nav-section-title">{title}</h3>}
      <NavSection>
        {links.map(
          (
            { href, label, isContactLink, items, title: subItemTitle, ...rest },
            idx,
          ) => (
            <li key={`${href}_${idx}`}>
              {isContactLink && (
                <button onClick={openContactForm} type="button" {...rest}>
                  {label}
                </button>
              )}
              {items ? (
                <NavItem>
                  <span>{subItemTitle}</span>
                  {items.map(
                    ({
                      href: subItemHref,
                      label: subItemLabel,
                      ...subItemRest
                    }) => (
                      <NavSubItem>
                        <Link
                          to={subItemHref}
                          {...subItemRest}
                          onClick={onClose}
                        >
                          {subItemLabel}
                        </Link>
                      </NavSubItem>
                    ),
                  )}
                </NavItem>
              ) : (
                <Link to={href} {...rest} onClick={onClose}>
                  {label}
                </Link>
              )}
            </li>
          ),
        )}
      </NavSection>
      {!isLast ? <hr /> : null}
    </li>
  );
};

export default MobileNavSection;
