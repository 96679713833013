import React from 'react';
import { liveCountries, notLiveCountries } from '../../data/all-countries';

export interface Field {
  label: React.ReactNode;
  type: string;
  id: string;
  options?: Option[];
  defaultValue?: string | number | boolean;
  validation?: Validation;
  validationMsg?: string;
  conditions?: Record<string, any>;
  width?: string;
}

export type Option = string | Record<string, string | number>;

interface Validation {
  required?: boolean;
  pattern?: RegExp;
}

const fields: Record<string, Field> = {
  FIRST_NAME: {
    label: 'First name',
    type: 'text',
    id: 'firstName',
    width: 'calc(50% - 7px)',
    validation: {
      required: true,
    },
  },
  LAST_NAME: {
    label: 'Last name',
    type: 'text',
    id: 'lastName',
    width: 'calc(50% - 7px)',
    validation: {
      required: true,
    },
  },
  EMAIL: {
    label: 'Work email',
    type: 'email',
    id: 'email',
    validation: {
      pattern: /[^@]+@[^\.](.+)?\.(.+)?/,
      required: true,
    },
  },
  COMPANY_NAME: {
    label: 'Company name',
    type: 'text',
    id: 'company',
    validation: {
      required: true,
    },
  },
  JOB_ROLE: {
    label: 'Job role',
    type: 'searchable',
    id: 'jobRole',
    options: [
      'Product/Technology',
      'Marketing/Partnerships',
      'Commercial/Sales',
      'Risk/Compliance',
      'Operations',
    ],
    validation: {
      required: true,
    },
  },
  PHONE: {
    label: 'Phone number',
    type: 'tel',
    id: 'phone',
    validation: {
      pattern: /([\d+() -])?/,
      required: true,
    },
  },
  LIVE_COUNTRY: {
    label: (
      <>
        <strong>
          Do you operate in any of the following operating countries?
        </strong>
        <br />
        {liveCountries.map(({ name }) => name).join(', ')}
      </>
    ),
    type: 'radio',
    id: 'liveCountry',
    options: ['Yes', 'No'],
    validation: {
      required: true,
    },
    validationMsg: 'Please select where you operate',
  },
  COUNTRY: {
    label: 'Select your country',
    conditions: { field: 'liveCountry', value: 'No' },
    type: 'searchable',
    id: 'country',
    options: notLiveCountries,
    validation: {
      required: true,
    },
  },
  USE_CASE: {
    label: 'I’m interested in using card-linking to:',
    type: 'searchable',
    id: 'useCase',
    options: [
      'Launch a new product',
      'Improve my existing product',
      'Advertise with a publisher',
      'I’d like to learn more about card-linking',
    ],
    validation: {
      required: true,
    },
    validationMsg: 'Please select',
  },
  MORE_INFO: {
    label: (
      <>
        <strong>Give us a little more info</strong>
        <br />
        What are you looking to build, develop or learn?
      </>
    ),
    type: 'textarea',
    id: 'moreInfo',
    validation: {},
  },
  SUBSCRIBE: {
    label:
      'Subscribe to stay in the loop on the latest industry, product and developer news',
    type: 'checkbox',
    id: 'shouldSubscribe',
    defaultValue: false,
  },
  LINKEDIN: {
    label: 'LinkedIn profile',
    id: 'linkedin',
    type: 'text',
    validation: {
      required: true,
    },
  },
};

export default fields;
