module.exports = {
  live: [
    {
      country: 'United Kingdom',
      code: 'gb',
      schemes: { mc: true, visa: true, amex: true },
    },
    {
      country: 'Ireland',
      code: 'ie',
      schemes: { mc: true, visa: true, amex: true },
    },
    {
      country: 'United States',
      code: 'us',
      schemes: { mc: true, visa: true, amex: false },
    },
    {
      country: 'Canada',
      code: 'ca',
      schemes: { mc: true, visa: true, amex: false },
    },
  ],
  beta: [
    {
      country: 'Sweden',
      code: 'se',
      schemes: { mc: false, visa: true, amex: true },
    },
    {
      country: 'Norway',
      code: 'no',
      schemes: { mc: true, visa: true, amex: true },
    },
    {
      country: 'Finland',
      code: 'fi',
      schemes: { mc: true, visa: true, amex: true },
    },
  ],
  comingSoon: [
    {
      country: 'Australia',
      code: 'au',
    },
    {
      country: 'New Zealand',
      code: 'nz',
    },
    {
      country: 'Singapore',
      code: 'sg',
    },
    {
      country: 'Japan',
      code: 'jp',
    },
  ],
};
