import React from 'react';

import { Offscreen } from './styled';

export interface Country {
  name: string;
  code: string;
  rounded: boolean;
}

export default function CountryFlag({ name, code, rounded }: Country) {
  const src = rounded
    ? require(`./assets/flags/rounded/${code.toLowerCase()}.png`).default
    : require(`./assets/flags/${code.toLowerCase()}.png`).default;

  return (
    <div>
      <Offscreen>{name}</Offscreen>
      <img style={{ width: '28px' }} className="flag" src={src} alt={name} />
    </div>
  );
}
