import React from 'react';
import styled from 'styled-components';

const Logos: React.FC = () => {
  const logos: [string, number][] = [
    ['NextJump', 32],
    ['TopCashback', 32],
    ['Avios', 42],
    ['Caruso', 42],
    ['Biltawards', 42],
  ];

  return (
    <LogoContainer>
      {logos.map(([logo, size]) => {
        const { src, srcSet } = getLogo(logo);
        return (
          <Logo
            key={logo}
            src={src}
            srcSet={srcSet}
            style={{ maxHeight: `${size}px` }}
          />
        );
      })}
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  height: 150px;

  @media (min-width: 800px) {
    height: auto;
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  width: auto;
  margin: 0 10px;
`;

function getLogo(name: string) {
  const fileName = name.toLowerCase();
  const src = require(`../static/images/logos/${fileName}.png`);
  const src2x = require(`../static/images/logos/${fileName}@2x.png`);

  return { src, srcSet: `${src.default}, ${src2x.default} 2x` };
}

export default Logos;
