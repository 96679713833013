import React from 'react';

import { OverlayTrigger } from '../../components';
import { FeatureCTA, CTAContainer } from '../../components/styled';
import ArrowIcon from '../../static/icons/arrow.svg';

import {
  CallToActionContainer,
  CallToActionTitle,
  BlueTextColor,
} from './styled';

const FooterCallToAction: React.FC = () => (
  <CallToActionContainer>
    <CallToActionTitle>
      Getting started is <br />
      <BlueTextColor>easy and free</BlueTextColor>
    </CallToActionTitle>
    <CTAContainer>
      <FeatureCTA
        id="dashboardSignUp-footer"
        as="a"
        href="https://dashboard.fidel.uk/sign-up"
        rel="noopener noreferrer"
        target="_blank"
        variant="secondary"
      >
        Get API key <ArrowIcon />
      </FeatureCTA>
      <FeatureCTA
        id="contactSales-footer"
        as={OverlayTrigger}
        target="contact_sales"
        variant="border"
      >
        Get in touch
      </FeatureCTA>
    </CTAContainer>
  </CallToActionContainer>
);

export default FooterCallToAction;
