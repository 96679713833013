import styled from 'styled-components';
import { colours, breakpoints } from '../../styled/variables';
import { CodeBody } from '../code/styled';

const getColor = ({ dark = true }) => colours[!dark ? 'black' : 'white'];

const getBackground = ({ dark = true }) =>
  colours[!dark ? 'lightGrey2' : 'codeBlockBg'];

export const CodeHeader = styled.div<{ dark?: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 30px 20px 4px;
  background: ${getBackground};
  border-radius: 16px;

  h4 {
    margin: 0;
    font-size: 14px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
    padding: 30px 24px;
  }
`;

export const RightElementContainer = styled.div`
  margin-bottom: 20px;
  max-width: 50vw;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
`;

export const CodeWindowContainer = styled.div<{ dark?: boolean }>`
  color: ${getColor};
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 30px 0;
  max-height: 80vh;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0;
    max-height: 100vh;
    min-height: 50vh;
  }
`;

export const CodeWindow = styled.div<{ dark?: boolean }>`
  width: 100%;
  margin: 0 0;
  position: relative;
  border-radius: 16px;
  background: ${getBackground};
  color: ${getColor};
  box-shadow: ${({ dark }) =>
    dark ? `0 24px 82px -22px rgba(17, 17, 17, 0.38)` : 'none'};

  ${CodeBody} {
    max-height: calc(100% - 120px);
    position: relative;

    pre {
      padding: 0 20px;

      @media (min-width: ${breakpoints.tablet}) {
        padding: 0 40px;
      }
    }

    @media (min-width: ${breakpoints.tablet}) {
      position: absolute;
    }
  }
`;
