import styled from 'styled-components';

import Button from './button';
import { breakpoints, colours } from './variables';

export const CtaTitle = styled.p`
  font-weight: 300;
  line-height: 30px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 22px;
  }

  @media (min-width: ${breakpoints.above13inch}) {
    font-size: 26px;
  }
`;

export const FeatureCTA = styled(Button as any).attrs(() => ({
  size: 'medium',
}))<{ variant?: keyof typeof colours | undefined; fontWeight?: number }>`
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

  @media (max-width: ${breakpoints.tabletPortrait}) {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    margin: 0 0 0 12px;
    transition: transform 0.2s ease-out;

    g {
      fill: ${props =>
        props && ['white', 'secondary'].includes(props.variant)
          ? colours.primary
          : colours.white};
    }
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      transform: translateX(6px);
      transition: transform 0.2s ease-in;
    }
  }

  ${({ variant }) =>
    variant === 'border' &&
    `
    border: 2px solid ${colours.white};
    background: transparent;
    color: ${colours.white};

    &:focus-visible{
      border: 2px solid ${colours.blueBorder};
    }

    &:hover,
    &:active {
      background: rgba(255, 255, 255, 0.16);
    }
  `}
`;

export const CTAContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  > :first-child {
    margin-right: 13px;
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}

    @media (max-width: ${breakpoints.tabletPortrait}) {
      display: flex;
    }
  }
`;
