import { createStore as reduxCreateStore, Reducer } from 'redux';

export interface State {
  overlay: string | null;
  locale: string | null;
  ctaSource?: string;
}

export interface Action {
  type: string;
  payload?: any;
}

export const reducer: Reducer<State, Action> = (
  state = makeInitialState(),
  action,
) => {
  switch (action.type) {
    case 'SHOW_OVERLAY':
      const { target, id } = action.payload;
      return { ...state, overlay: target, ctaSource: id };
    case 'HIDE_OVERLAY':
      if (currentPathMatches('contact')) redirectTo('');
      return { ...state, overlay: null, ctaSource: null };
    case 'SET_LOCALE':
      const locale = action.payload;
      localStorage.setItem('fidel-locale', locale);
      return { ...state, locale };
    default:
      return state;
  }
};

export const makeInitialState = () => ({
  overlay: null,
  locale: null,
});

const createStore = () => reduxCreateStore(reducer, makeInitialState());

function currentPathMatches(path: string) {
  return window?.location?.pathname?.includes(path);
}

function redirectTo(path) {
  history?.pushState({ url: `/${path}` }, path, `/${path}`);
}

export default createStore;
