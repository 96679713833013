import { useEffect, useState } from 'react';
import axios from 'axios';

import config from '../utils/config';

export interface Position {
  id?: string;
  title: string;
  offices?: Office[];
  departments?: Department[];
  absolute_url: string;
}

interface Department {
  id: string;
  name: string;
}

interface Office {
  id: string;
  name: string;
}

const officesNames = {
  'New York': 'NYC',
  'United States': 'USA',
  'United Kingdom': 'UK',
};

function parseName(name: string): string {
  return officesNames[name] || name;
}

function parseOffices(offices: any[]): Office[] {
  return offices.map(({ id, name }) => ({
    id,
    name: parseName(name),
  }));
}

function useJobsData() {
  const [positions, setPositions] = useState<Position[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedPositions = await axios.get(`${config.baseUrl}/jobs`);
        setPositions(fetchedPositions.data.jobs);

        const fetchedOffices = await axios.get(
          `${config.baseUrl}/jobs/offices`,
        );
        setOffices(parseOffices(fetchedOffices.data.offices));

        const fetchedDepartments = await axios.get(
          `${config.baseUrl}/jobs/departments`,
        );
        setDepartments(fetchedDepartments.data.departments);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return { positions, offices, departments };
}

export default useJobsData;
