import React from 'react';
import styled from 'styled-components';
import RcSelect, { Option as RcOption } from 'rc-select';

import { colours } from './styled/variables';
import Clear from './assets/close_circle.svg';
import Chevron from './assets/chevron.svg';

const Select = styled(RcSelect).attrs(() => ({
  getPopupContainer: (trigger: any) => trigger.parentNode,
  menuItemSelectedIcon: null,
  clearIcon: <Clear />,
  inputIcon: <Chevron />,
}))`
  &.rc-select {
    display: flex;
    align-items: center;
    width: 100%;
    color: ${colours.primary};

    .rc-select-selection-search {
      display: none;
    }
    .rc-select-selector {
      border: 2px solid ${colours.border2};
      font-size: 18px;
      color: ${colours.darkGrey};
      border-radius: 4px;
      cursor: pointer;
      padding: 10px 20px;
      width: 100%;
      position: relative;

      &:hover,
      &:active {
        background: ${colours.midGrey3};
      }

      &:focus-visible {
        background: ${colours.darkGrey3};
        outline: 2px solid ${colours.blueBorder};
      }
    }
    .rc-select-selection-item,
    .rc-select-selection-placeholder {
      position: relative;
    }
    .rc-select-arrow {
      right: 20px;
      top: 36%;
      color: ${colours.darkGrey};
      transform: rotateZ(0);
      transition: ease 0.3s;

      svg {
        height: 12px;
        width: 12px;
      }
    }

    .rc-select-clear {
      right: 16px;
      top: 17px;
      cursor: pointer;
    }
  }

  &.rc-select-disabled {
    .rc-select-selector {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .rc-select-arrow {
      opacity: 0.7;
    }
  }

  &.rc-select-open {
    .rc-select-arrow {
      top: 18% !important;
      transform: rotateZ(180deg);
    }
  }

  // Menu
  .rc-select-dropdown {
    min-height: auto;
    width: max-content !important;
    border: none;
    border-radius: 8px;
    z-index: 1;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);

    .rc-virtual-list {
      border-radius: 4px;
      padding: 5px;
    }

    .rc-select-item {
      padding: 10px 15px;
      border-radius: 8px;
    }

    .rc-select-item-option-active {
      background: ${colours.border2};
      cursor: pointer;
    }

    .rc-select-item-option-selected {
      background: white;
      a {
        font-weight: 600;
      }

      &:hover {
        background: ${colours.border2};
      }
    }
  }
`;

export const Option = styled(RcOption)``;

export default Select;
