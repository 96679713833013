import styled from 'styled-components';
import { breakpoints, colours } from './variables';

export const FinePrint = styled.small`
  font-size: 12px;
  opacity: 0.72;

  a {
    border-bottom-width: 1px;
  }
`;

export const HeroHeading = styled.h1`
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.48px;
  line-height: 1.22;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 36px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 42px;
  }

  @media (min-width: ${breakpoints.above13inch}) {
    font-size: 46px;
  }
`;

interface HeadingProps {
  weight?: number;
  lineHeight?: string;
  size?: number;
  color?: string;
}

export const Heading = styled.h2<HeadingProps>`
  margin: 20px 0;
  font-size: ${props => props?.size || '36px'};
  font-weight: ${props => props?.weight || 900};
  line-height: ${props => props?.lineHeight || '72px'};
  letter-spacing: 0.5px;
  color: ${props => props?.color};

  strong {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.tabletPortrait}) {
    font-size: 26px;
  }

  @media (min-width: ${breakpoints.above13inch}) {
    font-size: 28px;
  }

  ${props =>
    props?.size &&
    `
    @media (min-width: ${breakpoints.tabletPortrait}) {
      font-size: ${props.size - 6}px;
    }

    @media (min-width: ${breakpoints.above13inch}) {
      font-size: ${props.size}px;
    }

    @media (max-width: ${breakpoints.tabletPortrait}) {
      font-size: 36px;
      line-height: 44px;
    }
  `}
`;

export const HideOnMobile = styled.span`
  @media (max-width: ${breakpoints.tabletPortrait}) {
    display: none;
  }
`;

const largeTextStyle = `
  @media (min-width: ${breakpoints.tablet}) {
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.43px;
    font-size: 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 22px;
  }

  @media (min-width: ${breakpoints.above13inch}) {
    font-size: 24px;
  }
`;

export const LargeText = styled.div`
  ${largeTextStyle}

  * {
    ${largeTextStyle}
  }
`;

export const Strong = styled.strong<{ weight?: number }>`
  font-weight: ${props => props?.weight || 700};
`;

export const StyledLink = styled.a`
  color: ${colours.accent};
  font: inherit;
  border: none;
  padding: 0;
  background: none;
  text-decoration: none;
  position: relative;
  border-bottom: 2px solid ${colours.lightAccent};

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: ${colours.accent};
    z-index: 2;
    transition: width 0.3s ease;
  }

  &:hover,
  &:focus {
    &::after {
      width: 100%;
      transition: width 0.3s ease;
    }
  }
`;
