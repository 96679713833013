import styled from 'styled-components';
import { colours, breakpoints } from '../../styled/variables';

export const CodeBody = styled.div`
  display: flex;
  width: 100%;
  overflow: scroll;
  margin: 0;
  scrollbar-width: thin;

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-corner {
    background: transparent;
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(91, 91, 91, 0.25);
  }

  pre {
    margin: 0;
    padding: 0;
    font-size: 12px;
    word-wrap: normal;
    white-space: pre;

    code {
      padding: 0;
      word-wrap: normal;
      white-space: pre;
    }

    @media (min-width: ${breakpoints.tablet}) {
      font-size: 14px;
    }
  }

  pre,
  code {
    -moz-tab-size: 2;
    -o-tab-size: 2;
    tab-size: 2;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;

    &.dark {
      .hljs-attr {
        color: white;
      }

      .hljs-string {
        color: ${colours.stringAccent};
      }
    }
  }
`;

export const LinesContainer = styled.div<{ dark?: boolean }>`
  position: sticky;
  left: 0;
  padding: 0 10px 0 20px;
  background: ${({ dark }) =>
    !dark ? colours.lightGrey2 : colours.codeBlockBg};
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ dark }) => (!dark ? colours.lightGrey8 : colours.darkInputBg)};
`;

export const Line = styled.span`
  width: 20px;
  margin: 0;
  font-size: 12px;
  font-family: AkkuratMono, monospace;
  text-align: right;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 14px;
  }
`;
