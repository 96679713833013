import styled from 'styled-components';
import { breakpoints } from './variables';

export interface SectionProps {
  maxWidth?: number;
  padding?: string;
  margin?: string;
  paddingTablet?: string;
  paddingDesktop?: string;
  fullWidth?: boolean;
  centered?: boolean;
  bgColor?: string;
  noVerticalMargin?: boolean;
}

export const Section = styled.div<SectionProps>`
  width: 100%;
  max-width: ${props => props.maxWidth || 1180}px;
  margin: ${props => (props.noVerticalMargin ? 0 : 20)}px auto;
  padding: ${props => props.padding || '0 20px'};
  ${props => props.centered && 'text-align: center;'}
  ${props => props.bgColor && `background: ${props.bgColor};`}
  
  
  &:first-child {
    padding-top: 30px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: calc(100vw - 40px);
    padding: ${props => props.paddingTablet || '40px 0 20px'};
    margin: ${props => (props.noVerticalMargin ? 0 : 20)}px auto;
    ${props => props.bgColor && 'border-radius: 16px;'}

    &:first-child {
      padding-top: 0;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: ${props =>
      props.paddingDesktop || props.paddingTablet || '50px 0'};

    &:first-child {
      padding-top: 0;
    }
  }

  ${({ fullWidth }) =>
    fullWidth
      ? `
        margin: 40px 0 !important;
        max-width: 100% !important;
        width: 100% !important;
      `
      : ``};
`;

export const SectionFooter = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;

  h5 {
    text-align: center;

    @media (min-width: ${breakpoints.tablet}) {
      text-align: left;
    }
  }
`;
