import React from 'react';
import styled from 'styled-components';
import { breakpoints } from './variables';

interface FlexProps {
  alignItems?: React.CSSProperties['alignItems'];
  breakpoint?: keyof typeof breakpoints;
  flow?: React.CSSProperties['flexFlow'];
  justifyContent?: React.CSSProperties['justifyContent'];
  columns?: number;
  breakFlow?: React.CSSProperties['flexFlow'];
  gap?: React.CSSProperties['gap'];
}

const Flex = styled.div<FlexProps>`
  display: flex;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  gap: ${props => props.gap || '0'};
  flex-flow: ${props => props.breakFlow || 'column wrap'};
  ${props => props.alignItems && `align-items: ${props.alignItems};`}

  @media (min-width: ${props => props.breakpoint || breakpoints.tablet}) {
    flex-flow: ${props => props.flow || 'row nowrap'};
    justify-content: ${props => props.justifyContent || 'space-between'};
    > * {
      ${props =>
        props.columns && `width: calc(${100 / props.columns}% - 20px);`}
    }
  }
`;

export default Flex;
