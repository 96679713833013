import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SwitchLocaleBtn, Wrapper, Panel, PanelItem } from './styled';
import { State } from '../../store';

import Chevron from '../assets/chevron.svg';
import FlagCA from '../assets/flag-ca.svg';
import FlagGlobal from '../assets/flag-global.svg';
import FlagGB from '../assets/flag-uk.svg';
import FlagUS from '../assets/flag-us.svg';

const locales = {
  CA: { name: 'Canada', flag: <FlagCA /> },
  GB: { name: 'United Kingdom', flag: <FlagGB /> },
  US: { name: 'United States', flag: <FlagUS /> },
  GLOBAL: { name: 'Global', flag: <FlagGlobal /> },
};

const SwitchLocale: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state: State) => state.locale);
  const [panelOpen, setPanelOpen] = useState(false);

  useEffect(() => {
    const localeFromLocalStorage = localStorage.getItem('fidel-locale') || '';

    const localeFromNavigator = navigator.language?.substr(3) || '';

    const detectedLocale = localeFromLocalStorage || localeFromNavigator;

    /* In an earlier version, we set localel to UK instead of GB.
     * To respect this setting, we change UK to GB instead of GLOBAL. */
    if (localeFromLocalStorage === 'UK') setLocale('GB');
    else if (!Object.keys(locales).includes(detectedLocale))
      setLocale('GLOBAL');
    else setLocale(detectedLocale);
  }, []);

  function setLocale(countryCode: string) {
    dispatch({ type: 'SET_LOCALE', payload: countryCode });
  }

  function handleClickLocale(key) {
    setLocale(key);
    setPanelOpen(false);
  }

  return (
    <Wrapper>
      <SwitchLocaleBtn
        active={panelOpen}
        onClick={() => {
          setPanelOpen(!panelOpen);
        }}
        data-testid="switch-loc-btn"
      >
        {locale && locales[locale || 'US'].flag}
        <Chevron style={{ marginLeft: '10px' }} />
      </SwitchLocaleBtn>
      {panelOpen && (
        <Panel className="panel">
          {Object.entries(locales).map(([key, { name }]) => (
            <PanelItem
              key={key}
              onClick={() => {
                handleClickLocale(key);
              }}
            >
              {name}
            </PanelItem>
          ))}
        </Panel>
      )}
    </Wrapper>
  );
};

export default SwitchLocale;
