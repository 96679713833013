import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import LogoSoloWhite from '../../../static/images/fidel/logo_solo_white.svg';
import LogoSoloBlack from '../../../static/images/fidel/logo_solo_black.svg';
import SelectTransactionsIcon from '../assets/select-transactions.svg';
import TransactionStreamIcon from '../assets/transaction-stream.svg';
import OffersApiIcon from '../assets/offers.svg';
import ArrowIcon from '../../../static/icons/arrow.svg';

import { breakpoints, colours } from '../../../components/styled/variables';
import { RoundedButton } from '../../../components/styled/button';
import { Flex } from '../../../components/styled';

interface AbsoluteProps {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export const Absolute = styled.div<AbsoluteProps>`
  position: absolute;
  ${({ top, right, bottom, left }) => `
    top: ${top || 'auto'};
    right: ${right || 'auto'};
    bottom: ${bottom || 'auto'};
    left: ${left || 'auto'};
  `}
`;

export const Nav = styled.nav`
  max-width: 1184px;
  margin: auto;

  @media (max-width: 440px) {
    display: none;
  }

  @media (min-width: 440px) {
    display: none;
  }

  @media (min-width: 925px) {
    display: none;
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NavList = styled(Flex)`
  list-style: none;
  margin: 0 0 0 40px;
  padding: 0;

  svg {
    transition: transform 0.8s ease;
    height: 12px;
    width: 12px;
  }
`;

export const NavDropdownMenuCategoryLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 4px 0;
  margin-left: 8px;
  color: ${colours.black};
`;

export const IconWrapper = styled.div`
  margin: 0 10px;
`;

export const FidelBlackLogo = styled(LogoSoloBlack)`
  width: 32px;
  height: 32px;

  @media (min-width: ${breakpoints.header}) {
    width: 40px;
    height: 40px;
  }
`;

export const FidelWhiteLogo = styled(LogoSoloWhite)`
  width: 40px;
  height: 40px;
`;

export const HeaderContainer = styled.header<{
  inverted?: boolean;
}>`
  position: fixed;
  width: 100%;
  height: 58px;
  top: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1000;
  background: ${colours.white};
  padding: 0;

  li,
  a {
    border: none;
    ${props => props.inverted && `color: ${colours.white};`}
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: block;
    height: 88px;
    padding: 20px 32px;
  }
`;

export const LoginButton = styled(RoundedButton)`
  white-space: nowrap;
`;

export const SignUpButton = styled(RoundedButton)`
  margin-left: 20px;
  white-space: nowrap;
`;

export const visiblePanel = css`
  opacity: 1 !important;
  visibility: visible;
  z-index: 100;
  transition: opacity 0.4s ease;
`;

export const NavDropdownMenuPanel = styled.div<{
  visible: boolean;
  firstLevel: boolean;
}>`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: max-content;
  min-width: 270px;
  max-width: 330px;
  top: ${props => (props.firstLevel ? '100%' : ' -11px;')};
  left: ${props => (props.firstLevel ? 'calc(-120px + 50%);' : '100%;')}
  margin-left: ${props => (props.firstLevel ? '0;' : '2px;')}
  transition: opacity 0.4s ease;
  z-index: 1;
  ${props => props.visible && visiblePanel}
`;

export const NavDropdownMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${colours.white};
  border: 1px solid ${colours.border};
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  list-style: none;

  &::before {
    top: 9px;
    left: calc(50% - 13px);
    width: 18px;
    height: 18px;
    box-shadow: -1px -2px 3px 0 rgba(0, 0, 0, 0.09);
  }

  &::after {
    top: 10px;
    left: calc(50% - 14px);
    width: 20px;
    height: 20px;
  }
`;

export const PanelSensitivityArea = styled.div`
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    left: calc(50% - 60px);
    width: 125px;
    height: 50px;
  }
`;

export const NavDropdownMenuItem = styled.li<{
  padding?: React.CSSProperties['padding'];
}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${props => props.padding && `padding: ${props.padding};`}

  .arrowRight, .chevronRight {
    opacity: 0;
  }

  &:hover {
    background: ${colours.lightGrey4};
    border-radius: 4px;
    transition: background 0.25s ease-in;

    > ${IconWrapper} > .arrowRight,
    > ${IconWrapper} > .chevronRight {
      opacity: 1;
      transition: opacity 0.25s ease-in;
    }
  }
`;

export const SubItemTitle = styled.p`
  font-weight: 700;
  color: ${colours.black};
  font-size: 16px;
  padding: 0;
  margin: 0 0 4px;
`;

export const SubItemDescription = styled.p`
  line-height: 20px;
  font-weight: 400;
  color: ${colours.primary} !important;
  font-size: 14px;
  opacity: 0.7;
  padding: 0;
  margin: 0;
`;

export const ChevronContainer = styled.span<{ open: boolean }>`
  margin-left: 10px;
  transform: rotate(${({ open }) => (open ? '180' : '0')}deg);
  transition: transform 0.25s ease-in;
`;

const iconStyles = {
  height: '32px',
  width: '32px',
  position: 'relative',
  top: '7px',
} as CSSProperties;

const arrowRightIconStyles = {
  height: '20px',
  width: '20px',
  position: 'relative',
  top: '2px',
} as CSSProperties;

export const submenuIcons = {
  selectTransactions: <SelectTransactionsIcon style={{ ...iconStyles }} />,
  transactionStream: <TransactionStreamIcon style={{ ...iconStyles }} />,
  offers: <OffersApiIcon style={{ ...iconStyles }} />,
  arrowRight: <ArrowIcon style={{ ...arrowRightIconStyles }} />,
};

export const MainSiteContent = styled.main`
  margin-top: 58px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 13%;
  }

  @media (min-width: ${breakpoints.above13inch}) {
    margin-top: 10%;
  }
`;
