import React, { useRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import Offscreen from './styled/offscreen';
import {
  breakpoints,
  colours,
  input,
  Size,
  ScreenSize,
} from './styled/variables';

function getHeight(size?: Size, screenSize: ScreenSize = 'smallScreen') {
  return size ? input.size[screenSize][size] : input.size[screenSize].normal;
}

function getPadding(size?: Size, variant?: string) {
  if (variant === 'material')
    switch (size) {
      case 'small':
        return '0';
      case 'medium':
        return '14px 14px 0';
      case 'large':
        return '14px 20px 0';
      default:
    }

  return undefined;
}

interface InputFieldProps {
  dark?: boolean;
  customSize?: Size; // 'size' is a reserved attribute for input element which causes type errors
  screenSize?: ScreenSize;
  variant?: string;
}

const InputField = styled.input<InputFieldProps>`
  ${input.defaults}
  color: ${props => (props.dark ? colours.white : colours.primary)};
  background: ${props => (props.dark ? colours.darkAccent : colours.border2)};
  height: ${props => getHeight(props.customSize, props.screenSize)};
  padding: ${props => getPadding(props.customSize, props.variant)};
  width: 100%;

  @media (min-width: ${breakpoints.above13inch}) {
    height: ${props => getHeight(props.customSize, 'largeScreen')};
  }

  &::placeholder {
    color: ${props =>
      props.dark ? 'rgba(255, 255, 255, .7)' : colours.placeholder};
  }

  &[type='checkbox'] {
    width: auto;
  }

  &:focus + span {
    top: 7px;
    font-size: 11px;
  }
`;

interface LabelProps {
  margin?: string;
  width?: string;
  variant?: string;
  size?: Size;
}

const Label = styled.label<LabelProps>`
  display: block;
  position: relative;
  cursor: text;
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.width && `width: ${props.width};`}

  ${props =>
    props.variant === 'material' &&
    `
    span {
      position: absolute;
      top: 22px;
      left: ${props.size === 'large' ? '20px' : '14px'};
      opacity: .6;
      transition: font-size .3s ease, top .3s ease;

      &.has-error,
      &.touched {
        top: 7px !important;
        font-size: 11px !important;
      }

      &.has-error {
        color: ${colours.red};
        opacity: 1;
      }

      ${!['medium', 'large'].includes(props.size || '') && 'display: none;'}
    }
  `}
`;

type RestProps = InputHTMLAttributes<HTMLInputElement>;
export interface InputProps
  extends LabelProps,
    Omit<InputFieldProps, 'customSize'> {
  label: string;
  hasError?: boolean;
  touched?: boolean;
}

const Input = React.forwardRef<any, Merge<InputProps, RestProps>>(
  (
    {
      label,
      margin,
      variant,
      width,
      size,
      hasError = false,
      touched = false,
      ...rest
    },
    ref,
  ) => {
    // eslint-disable-next-line no-param-reassign
    ref = (ref as React.MutableRefObject<any>) || useRef();

    return (
      <Label variant={variant} width={width} margin={margin} size={size}>
        {variant !== 'material' && <Offscreen>{label}</Offscreen>}
        <InputField ref={ref} variant={variant} customSize={size} {...rest} />
        {variant === 'material' && (
          <span
            className={`
            ${(ref.current && ref.current.value) || touched ? 'touched ' : ''}
            ${hasError && 'has-error'}
          `}
          >
            {label}
          </span>
        )}
      </Label>
    );
  },
);

export default Input;
