import styled from 'styled-components';

import Link from '../../../components/link';
import { colours, breakpoints } from '../../../components/styled/variables';
import LogoFullWhite from '../../../static/images/fidel/logo-full-white.svg';

export const FooterDisclaimer = styled.p`
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const MobileFooterDisclaimer = styled.p`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
  }
`;

export const Footer = styled.footer`
  background: ${colours.black};
  color: ${colours.white};

  p {
    color: ${colours.white};
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 30px;
  }
`;

export const FooterMain = styled.div`
  padding: 30px 20px 0;
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 440px) {
    padding: 30px 20px 0 !important;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 30px 20px 0;
    min-height: calc(50vh - 180px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
`;

export const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 60px;
  }

  @media (max-width: ${breakpoints.tabletPortrait}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 60px;
  }
`;

export const CallToActionTitle = styled.h2`
  line-height: 1.3;
  font-weight: 900;
  font-size: 48px;

  @media (min-width: 440px) {
    font-size: 36px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 38px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 48px;
  }
`;

export const BlueTextColor = styled.span`
  color: ${colours.titleAccent};
`;

export const NavList = styled.ul`
  padding: 0;
  list-style: none;
  width: auto;

  li {
    margin-bottom: 8px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: max-content;
  }
`;

export const NavSection = styled.section`
  width: 160px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 440px) {
    margin: 0;
    display: block;
  }

  @media (min-width: ${breakpoints.tabletPortrait}) {
    margin-bottom: 30px;
    margin: 0;
  }

  h4 {
    margin: 0;
  }
`;

export const FooterBottom = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 30px 20px 0;

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: 0px;
  }

  @media (max-width: 440px) {
    margin-top: 0px;
  }
`;

export const FooterTerms = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 15px;
  border-bottom: 1px solid ${colours.darkGrey2};

  div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    button {
      position: relative;
      top: 3px;
      color: white;
    }
  }

  @media (max-width: 440px) {
    display: none;
  }
`;

export const FooterTermsMobile = styled.div`
  display: none;

  @media (max-width: 440px) {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;

    padding-bottom: 25px;
    border-bottom: 1px solid ${colours.darkGrey2};

    ul {
      display: block;
    }

    div {
      margin-top: 15px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      svg {
        position: relative;
        bottom: 0px;
        left: 0px;
      }

      button {
        color: white;
      }
    }
  }
`;

export const FooterLogoContainerMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FooterLegalLinks = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  margin-right: 45px;

  li {
    margin: 0 15px;
  }

  @media (max-width: 440px) {
    li {
      margin: 0;
    }

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const FooterLink = styled(Link)`
  color: ${colours.midGrey2};
  border-bottom-color: transparent;
  transition: border-bottom-color 0.3s ease;

  &:hover,
  &:focus {
    color: inherit;
    border-bottom-color: ${colours.white};
    transition: border-bottom-color 0.2s ease;
  }
`;

export const FooterCopyright = styled.div`
  padding: 20px 0 70px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 15px;

  p {
    font-size: 13px;
    margin: 3px 0;
    line-height: 16px;
    color: ${colours.midGrey3};

    @media (max-width: ${breakpoints.tablet}) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 1250px) {
    padding-bottom: 50px;
  }
`;

export const SocialLink = styled(Link)`
  border: none;
  transition: opacity 0.3s ease;

  &:not(:first-of-type) {
    margin-left: 14px;

    @media (max-width: 600px) {
      margin-left: 20px;
    }
  }

  &:hover,
  &:focus {
    border: none;
    opacity: 0.85;
  }
`;

export const FidelWhiteLogo = styled(LogoFullWhite)`
  width: 132px;
  height: 22px;
`;

export const FooterNav = styled.nav`
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: space-between;
`;

export const WrappedSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (min-width: ${breakpoints.desktop}) {
    gap: 2.5rem;
  }
`;
