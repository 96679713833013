import React from 'react';

import Form from '../form';
import fields from '../form/common-fields';

const ContactPartnerships: React.FC = () => {
  const props = {
    title: 'Talk to Partnerships',
    formUrl: 'contact-partnerships',
    shouldCloseOnEsc: false,
    sideContent: (
      <p>
        Leave your details and we’ll be in touch shortly to discuss how you can
        use card-linking to grow your business.
      </p>
    ),
    fields: [
      fields.FIRST_NAME,
      fields.LAST_NAME,
      fields.EMAIL,
      fields.COMPANY_NAME,
      fields.JOB_ROLE,
      fields.PHONE,
      fields.USE_CASE,
      fields.LIVE_COUNTRY,
      fields.COUNTRY,
      fields.MORE_INFO,
      fields.SUBSCRIBE,
    ],
  };

  return <Form {...props} />;
};

export default ContactPartnerships;
