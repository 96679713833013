import React from 'react';

import Code, { CodeProps } from '../code';

import {
  CodeHeader,
  CodeWindow as StyledCodeWindow,
  CodeWindowContainer,
  RightElementContainer,
} from './styled';

const CodeWindow: React.FC<CodeProps> = props => {
  const { title, rightElement, dark = true } = props;
  const Header = () => (
    <CodeHeader dark={dark}>
      {title && <h4>{title}</h4>}
      <RightElementContainer>{rightElement}</RightElementContainer>
    </CodeHeader>
  );

  return (
    <CodeWindowContainer dark={dark}>
      <StyledCodeWindow dark={dark}>
        <Header />
        <Code dark={dark} {...props} />
      </StyledCodeWindow>
    </CodeWindowContainer>
  );
};

export default CodeWindow;
