import React from 'react';

import FooterNav from './footer-nav';
import FooterCallToAction from './footer-cta';
import FooterBottom from './footer-bottom';

import { Footer as FooterContainer, FooterMain } from './styled';

const Footer: React.FC = () => (
  <FooterContainer>
    <FooterMain>
      <FooterCallToAction />
      <FooterNav />
    </FooterMain>
    <FooterBottom />
  </FooterContainer>
);

export default Footer;
