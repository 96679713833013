import React from 'react';
import styled from 'styled-components';

const Box = styled.input`
  margin-right: 8px;
`;

const Label = styled.label`
  display: flex;
  align-items: baseline;
`;

interface CheckboxProps {
  label: string;
}
type RestProps = React.InputHTMLAttributes<HTMLInputElement>;

const Checkbox = React.forwardRef<any, Merge<CheckboxProps, RestProps>>(
  ({ label, ...rest }, ref) => (
    <Label>
      <Box {...rest} type="checkbox" ref={ref} />
      {label}
    </Label>
  ),
);

export default Checkbox;
